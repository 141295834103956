<script>
export default {
  name: 'LogoHyattZilara',
  props: {
    fill: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
    },
  },
}
</script>

<template>
  <!-- eslint-disable max-len -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 70 500 100"
    :aria-labelledby="label"
    role="presentation"
    :class="{ 'fill-current': fill }"
  >
    <g id="hyatt_zilara">
      <g>
        <g>
          <path
            :fill="fill ? '#58595B' : 'currentColor'"
            d="M103.3,157.7l0.7,1.7h1.8l-4.2-9h0l-4.2,9h1.8l0.7-1.7H103.3z M101.6,153.5l1.2,3h-2.5L101.6,153.5z"
          />
          <polygon
            :fill="fill ? '#58595B' : 'currentColor'"
            points="111.1,150.5 111.1,159.4 116.6,159.4 116.6,157.9 112.8,157.9 112.8,150.5 "
          />
          <polygon
            :fill="fill ? '#58595B' : 'currentColor'"
            points="122.1,150.5 122.1,159.4 127.6,159.4 127.6,157.9 123.8,157.9 123.8,150.5 "
          />
        </g>
        <g>
          <rect
            x="142.4"
            y="150.5"
            :fill="fill ? '#58595B' : 'currentColor'"
            width="1.8"
            height="9"
          />
          <polygon
            :fill="fill ? '#58595B' : 'currentColor'"
            points="158.1,150.5 156.3,150.5 156.3,155.6 150.5,150.3 150.5,150.3 150.5,159.4 152.2,159.4
152.2,154.3 158.1,159.6 "
          />
          <path
            :fill="fill ? '#58595B' : 'currentColor'"
            d="M169,158c-1.9,0-3-1.2-3-3s1.2-3,3-3c0.7,0,1.4,0.2,2.1,0.5l0.5-1.5c-0.8-0.5-1.8-0.7-2.7-0.7
c-2.9,0-4.9,1.9-4.9,4.6c0,2.7,2,4.6,4.9,4.6c1,0,2-0.3,2.8-0.7l-0.5-1.5C170.5,157.8,169.7,158,169,158z"
          />
          <polygon
            :fill="fill ? '#58595B' : 'currentColor'"
            points="177.4,150.5 177.4,159.4 183,159.4 183,157.9 179.2,157.9 179.2,150.5 "
          />
          <path
            :fill="fill ? '#58595B' : 'currentColor'"
            d="M195.5,150.5h-1.8v5.2c0,1.5-0.7,2.3-2,2.3c-1.3,0-2-0.8-2-2.3v-5.2H188v5.4c0,2.5,1.3,3.8,3.7,3.8
c2.5,0,3.8-1.3,3.8-3.8V150.5z"
          />
          <path
            :fill="fill ? '#58595B' : 'currentColor'"
            d="M204.9,154.3c-1.2-0.5-1.8-0.8-1.8-1.5c0-0.4,0.3-1,1.3-1c0.6,0,1.4,0.3,1.9,0.7l0.4-1.5
c-0.5-0.3-1.4-0.6-2.4-0.6c-2.1,0-3.1,1.3-3.1,2.5c0,1.2,0.7,2,2.1,2.6c1.4,0.6,1.8,0.9,1.8,1.6c0,0.6-0.5,1-1.3,1
c-0.8,0-1.7-0.3-2.5-0.8l-0.5,1.6c0.8,0.5,2,0.8,2.9,0.8c2.2,0,3.1-1.4,3.1-2.7C207,155.9,206.7,155,204.9,154.3z"
          />
          <rect
            x="212.8"
            y="150.5"
            :fill="fill ? '#58595B' : 'currentColor'"
            width="1.8"
            height="9"
          />
          <polygon
            :fill="fill ? '#58595B' : 'currentColor'"
            points="228.5,150.5 226.5,150.5 224.2,155.9 221.9,150.5 219.9,150.5 224.2,159.5 "
          />
          <polygon
            :fill="fill ? '#58595B' : 'currentColor'"
            points="239.2,152.1 239.2,150.5 233.7,150.5 233.7,159.4 239.2,159.4 239.2,157.9 235.5,157.9
235.5,155.6 238.9,155.6 238.9,154.1 235.5,154.1 235.5,152.1 "
          />
        </g>
        <g>
          <path
            :fill="fill ? '#58595B' : 'currentColor'"
            d="M259.5,157.7l0.7,1.7h1.8l-4.2-9h0l-4.2,9h1.8l0.7-1.7H259.5z M257.8,153.5l1.2,3h-2.5L257.8,153.5z"
          />
          <path
            :fill="fill ? '#58595B' : 'currentColor'"
            d="M269.8,150.4c-0.9,0-1.6,0-2.6,0.1v8.8c1,0.1,1.6,0.1,2.7,0.1c3.4,0,5.4-1.7,5.4-4.6
C275.3,152.1,273.3,150.4,269.8,150.4z M270.3,158c-0.5,0-0.7,0-1.1-0.1l-0.2,0V152l0.2,0c0.4,0,0.6-0.1,0.9-0.1
c2.1,0,3.3,1.1,3.3,2.9C273.4,156.9,272.3,158,270.3,158z"
          />
          <path
            :fill="fill ? '#58595B' : 'currentColor'"
            d="M288.6,150.5h-1.8v5.2c0,1.5-0.7,2.3-2,2.3c-1.3,0-2-0.8-2-2.3v-5.2H281v5.4c0,2.5,1.3,3.8,3.7,3.8
c2.5,0,3.8-1.3,3.8-3.8V150.5z"
          />
          <polygon
            :fill="fill ? '#58595B' : 'currentColor'"
            points="294.7,150.5 294.7,159.4 300.2,159.4 300.2,157.9 296.5,157.9 296.5,150.5 "
          />
          <polygon
            :fill="fill ? '#58595B' : 'currentColor'"
            points="311,150.5 303.6,150.5 303.6,152.1 306.4,152.1 306.4,159.4 308.2,159.4 308.2,152.1 311,152.1
"
          />
        </g>
        <g>
          <path
            :fill="fill ? '#58595B' : 'currentColor'"
            d="M328.6,156.4c0.1,0,0.2,0,0.3,0l0.1,0l1.6,3.1h2l-2.1-3.5l0.2-0.1c1-0.6,1.5-1.5,1.5-2.5
c0-1.9-1.4-2.9-3.9-2.9c-0.9,0-1.7,0-2.6,0.1v8.9h1.8v-3.1l0.4,0C328.2,156.4,328.4,156.4,328.6,156.4z M327.6,155V152l0.2,0
c0.3-0.1,0.5-0.1,0.9-0.1c1.1,0,1.7,0.6,1.7,1.4c0,1-0.7,1.6-1.9,1.6c-0.3,0-0.4,0-0.7,0L327.6,155z"
          />
          <polygon
            :fill="fill ? '#58595B' : 'currentColor'"
            points="343.7,152.1 343.7,150.5 338.2,150.5 338.2,159.4 343.7,159.4 343.7,157.9 339.9,157.9
339.9,155.6 343.4,155.6 343.4,154.1 339.9,154.1 339.9,152.1 "
          />
          <path
            :fill="fill ? '#58595B' : 'currentColor'"
            d="M353.1,154.3c-1.2-0.5-1.8-0.8-1.8-1.5c0-0.4,0.3-1,1.3-1c0.6,0,1.4,0.3,1.9,0.7l0.4-1.5
c-0.5-0.3-1.4-0.6-2.4-0.6c-2.1,0-3.1,1.3-3.1,2.5c0,1.2,0.7,2,2.1,2.6c1.4,0.6,1.8,0.9,1.8,1.6c0,0.6-0.5,1-1.3,1
c-0.8,0-1.7-0.3-2.5-0.8l-0.5,1.6c0.8,0.5,2,0.8,2.9,0.8c2.2,0,3.1-1.4,3.1-2.7C355.2,155.9,354.9,155,353.1,154.3z"
          />
          <path
            :fill="fill ? '#58595B' : 'currentColor'"
            d="M365.2,150.3c-2.7,0-4.6,1.9-4.6,4.6c0,2.7,1.9,4.6,4.5,4.6c2.7,0,4.6-1.9,4.6-4.6
C369.7,152.2,367.9,150.3,365.2,150.3z M365.1,158.1c-1.6,0-2.7-1.3-2.7-3.1c0-1.8,1.1-3.1,2.7-3.1c1.6,0,2.7,1.3,2.7,3.1
C367.8,156.8,366.7,158.1,365.1,158.1z"
          />
          <path
            :fill="fill ? '#58595B' : 'currentColor'"
            d="M378.5,156.4c0.1,0,0.2,0,0.3,0l0.1,0l1.6,3.1h2l-2.1-3.5l0.2-0.1c1-0.6,1.5-1.5,1.5-2.5
c0-1.9-1.4-2.9-3.9-2.9c-0.9,0-1.7,0-2.6,0.1v8.9h1.8v-3.1l0.4,0C378.1,156.4,378.2,156.4,378.5,156.4z M377.5,155V152l0.2,0
c0.3-0.1,0.5-0.1,0.9-0.1c1.1,0,1.7,0.6,1.7,1.4c0,1-0.7,1.6-1.9,1.6c-0.3,0-0.4,0-0.7,0L377.5,155z"
          />
          <polygon
            :fill="fill ? '#58595B' : 'currentColor'"
            points="394.6,150.5 387.2,150.5 387.2,152.1 390,152.1 390,159.4 391.8,159.4 391.8,152.1 394.6,152.1
"
          />
        </g>
        <g>
          <path
            :fill="fill ? '#58595B' : 'currentColor'"
            d="M373.2,128.4v-1.4h-13V97.1c0-2.4-2-3.3-3.3-3.3h-1.4v37.5h14.7c1.2,0,2.2-0.6,2.7-1.5
C373.1,129.2,373.2,128.6,373.2,128.4z"
          />
          <path
            :fill="fill ? '#58595B' : 'currentColor'"
            d="M431.9,119.4l0.5-0.1c7.6-1.1,12.3-6,12.3-12.8c0-7.4-6.1-12.7-14.8-12.7h-7.8v37.5l1.2,0
c1.3,0,3.2-0.9,3.2-3.3v-7.5c0,0,10.3,8.2,11.7,9.3c1.8,1.4,4.4,1.5,5.2,1.5l3.5-0.1L431.9,119.4z M426.6,115.6V97.9h3.2
c6.4,0,10.5,3.4,10.5,8.7c0,5.5-4.1,9-10.7,9H426.6z"
          />
          <path
            :fill="fill ? '#58595B' : 'currentColor'"
            d="M339.2,93.8l-1.3,0c-1.3,0-3.3,0.9-3.3,3.2v34.3l1.3,0c1.6,0,3.3-1.1,3.3-3.4V93.8z"
          />
          <path
            :fill="fill ? '#58595B' : 'currentColor'"
            d="M385.2,128.6c0.1-0.2,3.2-7.9,3.2-7.9h12c0,0,3.1,7.9,3.2,7.9c1,2.8,3.8,2.8,4,2.8l1.9,0L394.6,93
l-15.1,38.4l1.9,0C382.4,131.3,384.4,130.7,385.2,128.6z M394.5,105.2l4.3,11.2h-8.7L394.5,105.2z"
          />
          <path
            :fill="fill ? '#58595B' : 'currentColor'"
            d="M461.2,128.6c0.1-0.2,2.2-5.5,3.2-7.8l0.1-0.1h12c0,0,3.1,7.9,3.2,7.9c1,2.8,3.8,2.8,4,2.8l1.9,0
L470.7,93l-15.1,38.4l1.9,0C458.5,131.3,460.5,130.7,461.2,128.6z M470.6,105.2l4.3,11.2h-8.7L470.6,105.2z"
          />
          <path
            :fill="fill ? '#58595B' : 'currentColor'"
            d="M151.6,114.1l12.1-20.1l-2.4,0c0,0-2.9,0-4.1,2.1c-0.4,0.6-7.9,13.4-7.9,13.4s-7.1-12-7.9-13.4
c-1.1-1.9-3.2-2.1-4-2.1c-0.1,0-2.6,0-2.6,0l12.1,20.2v17.3l1.7,0c1.5,0,3-1.1,3-3.1V114.1z"
          />
          <path
            :fill="fill ? '#58595B' : 'currentColor'"
            d="M174,128.6c0.1-0.2,2.2-5.5,3.2-7.8l0,0l0.1-0.1h12c0,0,3.1,7.9,3.2,7.9c1,2.8,3.8,2.8,4,2.8l1.9,0
L183.4,93l-15.1,38.4l1.9,0C171.2,131.3,173.2,130.7,174,128.6z M183.3,105.2l4.3,11.2h-8.7L183.3,105.2z"
          />
          <path
            :fill="fill ? '#58595B' : 'currentColor'"
            d="M267.2,96.6c0-1.3-1-2.7-2.7-2.7h-24.6l0,1.6c0,1.3,1,2.7,2.7,2.7h8.6v30.1c0,2,1.5,3.1,3,3.1h1.7V98.2
h11.3L267.2,96.6z"
          />
          <path
            :fill="fill ? '#58595B' : 'currentColor'"
            d="M230.9,96.6c0-1.3-1-2.7-2.7-2.7h-24.6l0,1.6c0,1.3,1,2.7,2.7,2.7h8.6v30.1c0,2,1.5,3.1,3,3.1h1.7V98.2
h11.3L230.9,96.6z"
          />
          <path
            :fill="fill ? '#58595B' : 'currentColor'"
            d="M102,114.8h15.2l0,13.7c0,1.9,1.5,2.9,3,3l1.7,0l0-37.4l-1.7,0c-1.4,0-3,1.1-3,3.1l0,13.5H102l0-13.6
c0-1.9-1.5-2.9-3-3l-1.7,0l0,37.4l1.6,0c1.5,0,3-1.1,3-3.1V114.8z"
          />
          <path
            :fill="fill ? '#58595B' : 'currentColor'"
            d="M316.4,127.2h-16.7l20.9-33.2h-25.9l0,1.5c0,1.3,1.1,2.7,3,2.7h14.9l-20.9,33.2h27.6v-1.5
c0-0.1,0-1.2-0.9-2C318,127.4,317.2,127.2,316.4,127.2z"
          />
        </g>
        <path
          :fill="fill ? '#58595B' : 'currentColor'"
          d="M487.6,94.7v3.3h-0.8v-3.3h-1.2v-0.8h3.3v0.8H487.6z M492.9,97.9l-0.1-2.2l-1.2,1.6h-0.1l-1.2-1.6l0,2.2
h-0.8l0.2-4.1h0.1l1.8,2.4l1.8-2.4h0.1l0.2,4.1H492.9z"
        />
        <path
          :fill="fill ? '#FFF32A' : 'currentColor'"
          d="M67.4,159.5h2c2.5,0,3.2-1.2,3.2-3.2c0-1.9,0-5.1,0-5.1L67.4,159.5z"
        />
        <path
          :fill="fill ? '#72BF44' : 'currentColor'"
          d="M49.2,158.9c0,0.4,0.4,0.6,0.8,0.6h3.3c0,0,6-9.5,5.9-9.4c-2.7,4.3-6.2,6.6-9.2,7.9
C50,158,49.2,158.3,49.2,158.9"
        />
        <polygon
          :fill="fill ? '#B2D235' : 'currentColor'"
          points="62.7,159.5 72.6,143.7 72.6,136.3 58,159.5 "
        />
        <path
          :fill="fill ? '#9ACA3C' : 'currentColor'"
          d="M58,159.5l14.7-23.2v-2.7c-2.5,1.4-5.2,3.5-7.4,7c0,0-2.9,4.6-2.9,4.6l-9.1,14.4H58z"
        />
        <polygon
          :fill="fill ? '#E2E41A' : 'currentColor'"
          points="6.6,100.4 16.6,84.7 11.9,84.7 6.6,93 "
        />
        <path
          :fill="fill ? '#FFF32A' : 'currentColor'"
          d="M11.9,84.7h-2c-2.5,0-3.2,1.2-3.2,3.2c0,1.9,0,5.1,0,5.1L11.9,84.7z"
        />
        <path
          :fill="fill ? '#72BF44' : 'currentColor'"
          d="M30,85.3c0-0.4-0.4-0.6-0.8-0.6h-3.3c0,0-6,9.5-5.9,9.4c2.7-4.3,6.2-6.6,9.2-7.9
C29.2,86.2,30,85.9,30,85.3"
        />
        <polygon
          :fill="fill ? '#B2D235' : 'currentColor'"
          points="16.6,84.7 6.6,100.4 6.6,107.9 21.3,84.7 "
        />
        <path
          :fill="fill ? '#9ACA3C' : 'currentColor'"
          d="M21.3,84.7L6.6,107.9v2.7c2.5-1.4,5.2-3.5,7.4-7c0,0,2.9-4.6,2.9-4.6l9.1-14.4H21.3z"
        />
        <polygon
          :fill="fill ? '#662D91' : 'currentColor'"
          points="32.5,140.8 37.1,140.8 49,122.1 44.3,122.1 "
        />
        <polygon
          :fill="fill ? '#282781' : 'currentColor'"
          points="39.6,122.1 27.8,140.8 32.5,140.8 44.3,122.1 "
        />
        <polygon
          :fill="fill ? '#204395' : 'currentColor'"
          points="34.9,122.1 34.9,122.1 23.1,140.8 27.8,140.8 39.6,122.1 "
        />
        <polygon
          :fill="fill ? '#1B3883' : 'currentColor'"
          points="34.9,122.1 30.3,122.1 18.4,140.8 23.1,140.8 "
        />
        <polygon
          :fill="fill ? '#1B3883' : 'currentColor'"
          points="11.3,159.5 15.9,159.5 27.8,140.8 23.1,140.8 "
        />
        <polygon
          :fill="fill ? '#204395' : 'currentColor'"
          points="23.1,140.8 18.5,140.8 18.4,140.8 6.6,159.5 11.3,159.5 "
        />
        <path
          :fill="fill ? '#201B57' : 'currentColor'"
          d="M31.2,150.1c2.8-4.4,5.9-9.3,5.9-9.3h-4.7l-11,17.4C24.5,157,28.3,154.7,31.2,150.1"
        />
        <path
          :fill="fill ? '#0061AA' : 'currentColor'"
          d="M21.4,158.2l11-17.4h-4.6l-11.9,18.7C15.9,159.5,18.4,159.4,21.4,158.2"
        />
        <polygon
          :fill="fill ? '#662D91' : 'currentColor'"
          points="46.8,103.4 42.1,103.4 30.3,122.1 34.9,122.1 "
        />
        <polygon
          :fill="fill ? '#282781' : 'currentColor'"
          points="39.6,122.1 51.4,103.4 46.8,103.4 34.9,122.1 "
        />
        <polygon
          :fill="fill ? '#204395' : 'currentColor'"
          points="44.3,122.1 44.3,122.1 56.1,103.4 51.4,103.4 39.6,122.1 "
        />
        <polygon
          :fill="fill ? '#1B3883' : 'currentColor'"
          points="44.3,122.1 49,122.1 60.8,103.4 56.1,103.4 "
        />
        <polygon
          :fill="fill ? '#1B3883' : 'currentColor'"
          points="68,84.7 63.3,84.7 51.4,103.4 56.1,103.4 "
        />
        <polygon
          :fill="fill ? '#204395' : 'currentColor'"
          points="56.1,103.4 60.7,103.4 60.8,103.4 72.6,84.7 68,84.7 "
        />
        <path
          :fill="fill ? '#201B57' : 'currentColor'"
          d="M48,94.1c-2.8,4.4-5.9,9.3-5.9,9.3h4.7l11-17.4C54.7,87.1,50.9,89.5,48,94.1"
        />
        <path
          :fill="fill ? '#0061AA' : 'currentColor'"
          d="M57.8,85.9l-11,17.4h4.6l11.9-18.7C63.3,84.7,60.9,84.8,57.8,85.9"
        />
        <polygon
          :fill="fill ? '#E2E41A' : 'currentColor'"
          points="62.7,159.5 67.4,159.5 72.6,151.1 72.6,143.7 "
        />
      </g>
    </g>
  </svg>
</template>
